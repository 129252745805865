/* This example requires Tailwind CSS v2.0+ */
import * as React from "react"
import { Fragment } from 'react'
import { Link } from "gatsby"
import { Popover, Transition } from '@headlessui/react'
import {
  
  MenuIcon,
  
  XIcon,
  GiftIcon,
} from '@heroicons/react/outline'
import {
  InformationCircleIcon,
  UsersIcon,
  MapIcon,
  QuestionMarkCircleIcon,
  ViewGridIcon,
  ChartPieIcon,
  CashIcon,
  UserGroupIcon,
} from '@heroicons/react/solid'
import {
  Twitter,
  Reddit,
  Instagram,
  Telegram,
  Discord
} from '@icons-pack/react-simple-icons';
import { ChevronDownIcon } from '@heroicons/react/solid'
import Logo from "../images/logo.svg"

const about = [
  {
    name: 'Features',
    href: '/#features',
    icon: ViewGridIcon,
  },
  {
    name: 'Roadmap',
    href: '/#roadmap',
    icon: MapIcon,
  },
  {
    name: 'Team',
    href: '/#team',
    icon: UserGroupIcon,
  },
  {
    name: 'FAQs',
    href: '/#faqs',
    icon: QuestionMarkCircleIcon,
  },
]

const socials = [
  {
    name: 'Twitter',
    href: 'https://twitter.com/TheRealDogeBTC',
    icon: Twitter,
  },
  {
    name: 'Reddit',
    href: 'https://www.reddit.com/r/TheRealDogeBTC/',
    icon: Reddit,
  },
  {
    name: 'Instagram',
    href: 'https://instagram.com/dogebtc.finance',
    icon: Instagram,
  },
  {
    name: 'Telegram',
    href: 'https://t.me/TheRealDogeBTC',
    icon: Telegram,
  },
  // {
  //   name: 'Discord',
  //   href: '#',
  //   icon: Discord,
  // },
]


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Header() {
  return (
    <Popover className="relative z-50 container">
      {({ open }) => (
        <>
          <div className="flex justify-between items-center py-6 xl:justify-start xl:space-x-10">
            <div>
              <Link to="/" className="flex">
                <span className="sr-only">DogeBTC Logo</span>
                <img
                  className="h-12 w-auto md:h-14 lg:h-16"
                  src={Logo}
                  alt=""
                />
              </Link>
            </div>
            <div className="-mr-2 -my-2 xl:hidden">
              <Popover.Button className="p-2 inline-flex items-center justify-center text-gray-800 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-transparent">
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <div className="hidden xl:flex-1 xl:flex xl:items-center xl:justify-end">
              <Popover.Group as="nav" className="flex space-x-8 items-start mt-1">
              <Link to="/dashboard" className="group inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none space-x-1.5">
                  <CashIcon className="w-6 h-6 text-yellow-500 mr-0.5 flex-shrink-0" />
                  <span>Dashboard</span>
                </Link>
                <Popover className="relative">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={classNames(
                          open ? 'text-gray-900' : 'text-gray-800',
                          'group inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none space-x-1.5'
                        )}
                      >
                        <InformationCircleIcon className="w-6 h-6 text-yellow-500 mr-0.5 flex-shrink-0" />
                        <span>About</span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? 'text-gray-900' : 'text-gray-800',
                            'h-5 w-5 flex-shrink-0 group-hover:text-gray-900'
                          )}
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel
                          static
                          className="absolute z-50 mt-3 lg:left-1/2 lg:-translate-x-1/2 transform w-72"
                        >
                          <div className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5 overflow-hidden">
                            <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                              {about.map((item) => (
                                <Popover.Button
                                  key={item.name}
                                  href={item.href}
                                  className="appearance-none -m-3 p-3 flex items-center rounded-lg hover:bg-yellow-50"
                                  as="a"
                                  type=""
                                >
                                  <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-full bg-yellow-100 text-yellow-500 sm:h-12 sm:w-12">
                                    <item.icon className="h-6 w-6" aria-hidden="true" />
                                  </div>
                                  <div className="ml-4">
                                    <p className="text-base font-medium text-gray-900">{item.name}</p>
                                  </div>
                                </Popover.Button>
                              ))}
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>

                <Popover className="relative">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={classNames(
                          open ? 'text-gray-900' : 'text-gray-800',
                          'group inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none space-x-1.5'
                        )}
                      >
                        <UsersIcon className="w-6 h-6 text-yellow-500 mr-0.5 flex-shrink-0" />
                        <span>Join Our Community</span>
                        <ChevronDownIcon
                          className={classNames(
                            open ? 'text-gray-900' : 'text-gray-800',
                            'h-5 w-5 flex-shrink-0 group-hover:text-gray-900'
                          )}
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel
                          static
                          className="absolute z-50 mt-3 lg:left-1/2 lg:-translate-x-1/2 transform w-72"
                        >
                          <div className="rounded-lg shadow-xl ring-1 ring-black ring-opacity-5 overflow-hidden">
                            <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                              {socials.map((item) => (
                                <a
                                  key={item.name}
                                  href={item.href}
                                  target-="_blank"
                                  className="-m-3 p-3 flex items-center rounded-lg hover:bg-yellow-50"
                                >
                                  <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-full bg-yellow-100 text-yellow-500 sm:h-12 sm:w-12">
                                    <item.icon className="h-6 w-6" aria-hidden="true" />
                                  </div>
                                  <div className="ml-4">
                                    <p className="text-base font-medium text-gray-900">{item.name}</p>
                                  </div>
                                </a>
                              ))}
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>

              </Popover.Group>
              <div className="flex items-center">
                <a
                  href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xb3fa62ed37e885e3f6b98f1bf9eaa724bb9aff39" target="_blank"
                  className="ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-lg shadow-sm text-base font-medium text-gray-900 bg-yellow-400 hover:bg-yellow-500"
                >
                  Buy DogeBTC
                </a>
              </div>
            </div>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              static
              className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right xl:hidden"
            >
              <div className="rounded-lg shadow-2xl ring-1 ring-black ring-opacity-5 bg-white divide-y divide-gray-200">
                <div className="pt-5 pb-6 px-5">
                  <div className="flex items-center justify-between">
                    <div className="text-2xl font-medium text-gray-900">
                      Menu
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="p-2 inline-flex items-center justify-center text-gray-800 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-transparent">
                        <span className="sr-only">Close menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="mt-6">
                    <nav className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-1">
                    <Link to="/dashboard" className="-m-3 p-3 flex items-center rounded-lg hover:bg-yellow-50">
                    <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-full bg-yellow-100 text-gray-900">
                            <CashIcon className="h-6 w-6 text-yellow-500" aria-hidden="true" />
                          </div>
                      <div className="ml-4 text-base font-medium text-gray-900">Dashboard</div>
                    </Link>
                      {about.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="-m-3 p-3 flex items-center rounded-lg hover:bg-yellow-50"
                        >
                          <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-full bg-yellow-100 text-gray-900">
                            <item.icon className="h-6 w-6 text-yellow-500" aria-hidden="true" />
                          </div>
                          <div className="ml-4 text-base font-medium text-gray-900">{item.name}</div>
                        </a>
                      ))}
                    </nav>
                  </div>
                </div>
                <div className="py-6 px-5">
                  <div className="sm:flex sm:flex-row sm:space-x-10 sm:justify-between sm:items-center">
                    <a
                      href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xb3fa62ed37e885e3f6b98f1bf9eaa724bb9aff39" target="_blank"
                      className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-lg shadow-sm text-base font-medium text-gray-900 bg-yellow-400 hover:bg-yellow-500 sm:max-w-xs"
                    >
                      Buy DogeBTC
                    </a>
                    <div className="mt-6 sm:mt-0 flex items-center justify-center space-x-5">
                      {socials.map((item) => (
                        <a href={item.href} key={item.name} target="_blank">
                          <item.icon className="w-6 h-6 text-gray-900" />
                        </a>

                      ))}

                    </div>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}
