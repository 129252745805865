import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import {
  Twitter,
  Reddit,
  Instagram,
  Telegram,
  Discord
} from '@icons-pack/react-simple-icons';

const socials = [
  {
    name: 'Twitter',
    href: 'https://twitter.com/TheRealDogeBTC',
    icon: Twitter,
  },
  {
    name: 'Reddit',
    href: 'https://www.reddit.com/r/TheRealDogeBTC/',
    icon: Reddit,
  },
  {
    name: 'Instagram',
    href: 'https://instagram.com/dogebtc.finance',
    icon: Instagram,
  },
  {
    name: 'Telegram',
    href: 'https://t.me/TheRealDogeBTC',
    icon: Telegram,
  },
  // {
  //   name: 'Discord',
  //   href: 'https://discord.gg/KxaHpHbQy4',
  //   icon: Discord,
  // },
]



export default function Footer() {
  return (
    <div>
      <footer className="bg-gray-50">
        <div className="py-12 container md:flex md:items-center md:justify-between">
          <div className="flex justify-center space-x-6 md:order-2">
            {socials.map((item) => (
              <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-600">
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </a>
            ))}
          </div>
          <div className="mt-8 md:mt-0 md:order-1">
            <p className="text-center text-base text-gray-400">&copy; 2021 DogeBTC.finance - All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>

  )
}